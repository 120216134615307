import React, { memo, useEffect, useState } from 'react';
import { Form, Row } from 'antd';
import { Modal } from '@components/modal';
import { Input } from '@components/input';
import { useDispatch, useSelector } from 'react-redux';
import {
    customerSelector,
    fetchCategories,
    fetchCustomersByDivision,
    orderItemsSelector,
    orderTypeSelector,
    selectTypePlatform,
    updateOrder,
    updateOrderPlatform,
} from '@redux/slices/order';
import { columns, filterModalSelectOptions } from '../constants';
import { Table } from '@components/table';
import { CustomerResponse } from '@models/customer';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { translate } from '@components/i18n';
import { FormItem, Option, Select } from '@styles/components/form';
import { FastBackwardOutlined, SearchOutlined } from '@ant-design/icons';
import { CATEGORY_WITH_VALUE_AR, IS_ARG, SHOW_CATEGORY } from '@config/env';
import { ModalSelectTypeForm } from './modal-select-type-form';
import { CardHeader } from './card-header';
import { Card } from '@styles/components/wrapper';

const defaultValues = {
    category: 'Ship'
}

export const SelectDeliveryCustomerModal: React.FC = memo(() => {
    const [queryState, setQueryState] = useState<string>('');
    const selectItemsState = useSelector(orderItemsSelector);
    const selectOrderTypeSelector = useSelector(orderTypeSelector);
    const selectCustomerState = useSelector(customerSelector);
    const [isVisibleModalBranch, setIsVisibleModalBranch] = useState<boolean>(false)
    const dispatch = useDispatch();

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerResponse>();

    useEffect(() => {
        if (SHOW_CATEGORY) {
            dispatch(fetchCategories())
        }
    }, []);

    useEffect(() => {
        if (SHOW_CATEGORY) {
            if (selectCustomerState.isSuccessCategories) {
                if (selectCustomerState.categories.length > 0) {
                    const hasSold = selectCustomerState.categories.find(category => category.nome === 'Ship')
                    if (hasSold) {
                        dispatch(fetchCustomersByDivision(({
                            ...selectCustomerState.requestForDelivery,
                            ...(!!CATEGORY_WITH_VALUE_AR && { categoria: defaultValues.category })
                        })));
                    }
                } else {
                    dispatch(
                        fetchCustomersByDivision(selectCustomerState.requestForDelivery),
                    );
                }
            }
        } else {
            dispatch(
                fetchCustomersByDivision(selectCustomerState.requestForDelivery),
            );
        }
    }, [selectCustomerState.isSuccessCategories])


    const onFiltersChange = (changedValues: any, values: any): void => {
        dispatch(
            fetchCustomersByDivision({
                ...selectCustomerState.requestForDelivery,
                ...values,
                filterValue: queryState,
                categoria: values.category
            }),
        );

    }

    const search = (): void => {
        dispatch(
            fetchCustomersByDivision({
                ...selectCustomerState.requestForDelivery,
                filterValue: queryState,
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value)
    };

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchCustomersByDivision({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const onSelect = (rowKey: CustomerResponse): void => {
        setSelectedCustomer(rowKey);
    };

    const onCancel = (): void => {
        setSelectedCustomer(undefined);
        dispatch(updateOrder({ addCustomer: false }));
    };

    const onOk = (): void => {
        if (IS_ARG) {
            setIsVisibleModalBranch(true)
            return
        }

        dispatch(updateOrder({ addCustomerSelected: selectedCustomer }));
        setSelectedCustomer(undefined);
    };

    const onSubmitBranchModal = (branchId: number): void => {

        const platform = selectOrderTypeSelector.availablePlatforms.find(
            (i) => Number(i.id) === branchId,
        );

        dispatch(updateOrder({ addCustomerSelected: selectedCustomer }));
        dispatch(selectTypePlatform({ platform: branchId }));

        if (platform) {
            dispatch(updateOrderPlatform(platform))
        }

        setIsVisibleModalBranch(false) // close modal branch
        setSelectedCustomer(undefined); // close this modal
    };

    const onCloseBranchModal = (): void => {
        dispatch(updateOrder({ addCustomerSelected: selectedCustomer }));
        setSelectedCustomer(undefined);
        setIsVisibleModalBranch(false) // close modal branch
    };

    return (
        <>
            <Modal
                title={translate('general.deliveryClient')}

                className={'bold-title'}
                visible={
                    selectItemsState.order?.addCustomer &&
                    !selectItemsState.order?.addCustomerSelected
                }
                width={'70%'}
                onOk={onOk}
                onCancel={onCancel}
            >
                <Row>
                    <Table
                        columns={columns}
                        pointer
                        rowKey={(record: CustomerResponse) => `${record.id}`}
                        dataSource={selectCustomerState.availableCustomersForDelivery.filter(
                            (c) => c.id !== selectItemsState.order?.customer?.id,
                        )}
                        onRowClick={onSelect}
                        pagination={selectCustomerState.requestForDelivery}
                        onChange={loadData}
                        scroll={{ y: '40vh' }}
                        selectedRowIndex={selectedCustomer?.id}
                        onFilterChange={onFiltersChange}
                        initialValues={{
                            ...(SHOW_CATEGORY && !!CATEGORY_WITH_VALUE_AR && { category: defaultValues.category })
                        }}
                        filters={[
                            <FormItem
                                label={translate(
                                    'forms.labels.searchFor',
                                )}
                                name={'filterBy'}
                                style={{ minWidth: 150 }}
                            >
                                <Select
                                    placeholder={translate(
                                        'general.all',
                                    )}
                                    allowClear
                                >
                                    {filterModalSelectOptions.map(
                                        (c) => (
                                            <Option value={c.value}>
                                                {c.label}
                                            </Option>
                                        ),
                                    )}
                                </Select>
                            </FormItem>,
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Input
                                    placeholder={translate(
                                        'forms.placeholders.search',
                                    )}
                                    allowClear
                                    suffix={
                                        <div
                                            onClick={search}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <SearchOutlined />
                                        </div>
                                    }
                                    onKeyUp={(e) => {
                                        if (
                                            e.code === `Enter` ||
                                            e.keyCode === 13
                                        ) {
                                            search();
                                        }
                                    }}
                                    onChange={(e) =>
                                        onSearch(e.target.value)
                                    }
                                />
                            </Form.Item>,
                            !!SHOW_CATEGORY && (
                                <FormItem
                                    label={translate(
                                        'forms.labels.category',
                                    )}
                                    name={'category'}
                                    style={{ minWidth: 150 }}
                                >
                                    <Select
                                        placeholder={translate(
                                            'general.all',
                                        )}
                                        allowClear
                                    >
                                        {selectCustomerState.categories.map(
                                            (c) => (
                                                <Option value={c.nome}>
                                                    {c.nome}
                                                </Option>
                                            ),
                                        )}
                                    </Select>
                                </FormItem>),
                        ]}
                    />
                </Row>

            </Modal>
            {isVisibleModalBranch && (
                <ModalSelectTypeForm
                    isVisible={isVisibleModalBranch}
                    onOk={onSubmitBranchModal}
                    onClose={onCloseBranchModal} />
            )}
        </>
    );
});
