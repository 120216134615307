import React, { memo, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { FormItem, Select, Option } from '@styles/components/form';
import { InputDate } from '@components/input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import { useQuery as useQueryParams } from '@hooks/use-query';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAvailablePlatform,
    fetchAvailablePlatforms,
    orderItemsSelector,
    orderTypeSelector,
} from '@redux/slices/order';
import { PlatformRequest } from '@models/order/platform/request';
import { isEsAr, isEsMx, translate } from '@components/i18n';
import { DO_NOT_SHOW_MONEY_SELECTION } from '@config/env';
import { Card } from '@styles/components/wrapper';
import { CardHeader } from './card-header';
import { Modal } from '@components/modal';

interface ModalSelectTypeFormProps {
    onOk: (branchId: number) => void
    onClose: () => void
    isVisible: boolean
}

export const ModalSelectTypeForm: React.FC<ModalSelectTypeFormProps> = memo(({ isVisible, onOk, onClose }) => {
    const query = useQueryParams()
    const isDraft = query.get('draft')
    const selectItemsState = useSelector(orderItemsSelector);

    const defaultValues: PlatformRequest = {
        date: selectItemsState?.order?.orderType?.date,
        platform: selectItemsState?.order?.orderType?.platform?.id,
        customerId: selectItemsState?.order?.customer?.id,
        type: selectItemsState?.order?.orderType?.type?.id,
    }

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const selectTypeState = useSelector(orderTypeSelector);
    const [formState, setFormState] = useState<PlatformRequest>(defaultValues);

    useEffect(() => {
        if (isDraft) {
            dispatch(addAvailablePlatform({
                id: selectItemsState?.order?.orderType?.platform?.id,
                description: selectItemsState?.order?.orderType?.platform.description,
                conven: undefined
            }));
        }
    }, [isDraft])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (_, data: any): void => {
        setFormState(prev => ({ ...prev, ...data }));

        if (data.type !== formState?.type) {
            dispatch(fetchAvailablePlatforms({ type: parseInt(data.type) }));
        }
    };

    const onSubmit = (): void => {
        onOk(Number(formState.platform))
    };

    useEffect(() => {
        if (
            !selectTypeState.isFetchingPlatform &&
            selectTypeState.isSuccessPlatform &&
            selectTypeState.availablePlatforms.length === 0
        )
            message.error(translate('general.filialAvailableError'), 3);
    }, [selectTypeState.isFetchingPlatform, selectTypeState.isSuccessPlatform]);

    return (
        <Modal
            title={translate('general.changeBranch')}
            className={'bold-title'}
            visible={isVisible}
            width={'30%'}
            onOk={onSubmit}
            onCancel={onClose}
        >
            <Card title={<CardHeader />} padding={'24px 15%'}>
                <Form
                    form={form}
                    onValuesChange={onChange}
                    layout="vertical"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={formState}
                >
                    <FormItem label={translate('forms.labels.orderType')} name={'type'}>
                        <Select
                            placeholder={translate('forms.placeholders.chooseOption')}
                            loading={selectTypeState.isFetching}
                            disabled
                        >
                            {selectTypeState.availableTypes
                                .filter((f) => `${f.id}` !== 'V1')
                                .map((t) => (
                                    <Option value={t.id}>{t.description}</Option>
                                ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        label={translate('forms.labels.originPlatform')}
                        name={'platform'}
                    >
                        <Select
                            placeholder={translate('forms.placeholders.chooseOption')}
                            loading={selectTypeState.isFetchingPlatform}
                            allowClear
                            disabled={!!isDraft}
                        >
                            {selectTypeState.availablePlatforms.map((t) => (
                                <Option value={t.id}>
                                    {t.id} - {t.description}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <Form.Item
                        name={'date'}
                        label={translate('forms.labels.scheduleOrder')}

                    >
                        <InputDate
                            locale={isEsAr() || isEsMx() ? localeEs : locale}
                            format={'DD/MM/YYYY'}
                            disabledDate={() => true}
                            placeholder={translate('forms.placeholders.chooseDate')}
                        />
                    </Form.Item>
                    {!DO_NOT_SHOW_MONEY_SELECTION && (
                        <FormItem
                            label={translate('forms.labels.moneyType')}
                            name={'moneyType'}
                        >
                            <Select
                                placeholder={translate('forms.placeholders.chooseOption')}
                                loading={selectItemsState.isFetchingMoeda}
                                allowClear
                                disabled
                            >
                                {selectItemsState.moedas.map((t) => (
                                    <Option value={t.codigo}>
                                        {`${t.codigo} - ${t.descricao}`}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    )}
                </Form>
            </Card>
        </Modal>
    );
});
