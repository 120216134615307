import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@components/page';
import { LeftOutlined } from '@ant-design/icons';
import { SelectItemsHeader } from './components/select-items-header';
import {
    SelectItemsBody,
    SelectItemsBodyBonification,
} from './components/select-items-body';
import { SelectItemsActions } from './components/select-items-actions';
import { SelectDeliveryCustomerModal } from './components/select-delivery-customer-modal';
import { ChangeDiscountContextProvider } from 'src/contexts/change-discount-modal-context';
import { ChangeDiscountModal } from './components/change-discount-modal';
import {
    customerSelector,
    draftsSelector,
    fetchAvailableItems,
    fetchAvailablePaymentConditions,
    fetchConvenio,
    fetchCustos,
    fetchMoedas,
    fetchMoedasSimbolos,
    fetchPaymentCondition,
    fetchPdiscqt,
    fetchSemaphores,
    orderItemsSelector,
    orderTypeSelector,
    resetBonification,
    selectCustomer,
    updateCampaignItems,
    updateMoeda,
    updateOrder,
    updateOrderState,
} from '@redux/slices/order';
import { useQuery } from '@hooks/use-query';
import axios from '@axios';
import { OrderResponseApi } from '@models/order/response';
import { scheduledSelector } from '@redux/slices/order/scheduled';
import { historySelector } from '@redux/slices/order/history';
import { calculateItemSemaphore } from '@redux/functions/order';
import { RootState } from '@redux/reducers';
import { DiscountWarning } from '@components/warning';
import { OrderContextProvider } from './context/provider';
import { campaignSelector } from '@redux/slices/campaign';
import { fetchMinValueForOrder } from '@redux/slices/min-order';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { AppDispatch } from '@redux/store';

export const SelectItems: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch<AppDispatch>();
    const { customer } = useSelector(customerSelector);
    const { orderType } = useSelector(orderTypeSelector);
    const { drafts } = useSelector(draftsSelector);
    const { scheduled } = useSelector(scheduledSelector);
    const { history: historyState } = useSelector(historySelector);
    const rootState = useSelector((state: RootState) => state);
    const campaignsState = useSelector(campaignSelector);
    const orderState = useSelector(orderItemsSelector);
    const [canFetchItems, setCanFetchItems] = useState<boolean>(false)

    const {
        discountPanteci,
        semaphores,
        custos,
        convenios,
        pdiscqt,
        order: orderHistory,
        selectedMoeda,
        moedaSimbolos,
        moedas,
        paymentCondition,
        ...selectItemsState
    } = useSelector(orderItemsSelector);

    const isDraft = query.get('draft');

    const goBack = (): void => history.goBack();

    useEffect(() => {
        dispatch(fetchMoedas())
        dispatch(fetchMoedasSimbolos())
    }, [])


    const fetchPreData = async (): Promise<void> => {
        const arrayDispatch = []
        if (isDraft === 'true' && moedas.length > 0 && moedaSimbolos.length > 0) {
            const moeda = moedas.find(item => item.codigo === orderHistory?.codigoMoeda)
            const simbolo = moedaSimbolos.find(item => item.codigoMoeda === orderHistory?.codigoMoeda)

            if (moeda) {
                dispatch(updateMoeda({
                    ...moeda,
                    simbolo: simbolo?.simbolo
                }))

                arrayDispatch.push(dispatch(fetchCustos({ moeda: moeda.codigo })).unwrap())
            }
        } else if (!isDraft && selectedMoeda?.codigo) {
            arrayDispatch.push(dispatch(fetchCustos({ moeda: selectedMoeda.codigo })).unwrap())
        }

        await Promise.allSettled([
            dispatch(fetchConvenio()).unwrap(),
            dispatch(fetchPdiscqt()).unwrap(), //
            dispatch(fetchSemaphores()).unwrap(),
            dispatch(fetchPaymentCondition({ data: orderHistory.customer.code })).unwrap(),
            dispatch(fetchAvailablePaymentConditions()).unwrap(),
            dispatch(resetBonification()),
            ...arrayDispatch,
        ])
    }

    useEffect(() => {
        if (orderHistory?.customer?.code && moedaSimbolos?.length && moedas?.length) {
            fetchPreData()
                .then(res => {
                    setCanFetchItems(true)
                })
        }
    }, [orderHistory?.customer?.code, isDraft, moedaSimbolos?.length, moedas?.length, customer?.typeId])

    useEffect(() => {
        const isDraft = query.get('draft');
        const scheduledOrder = query.get('scheduled');
        const readOnly = query.get('readOnly');

        if (isDraft === 'true') {
            const ref = query.get('ref');
            const order = scheduledOrder
                ? scheduled.find((d) => `${d.draftRef}` === ref)
                : readOnly
                    ? historyState.find((d) => `${d.draftRef}` === ref)
                    : drafts.find((d) => `${d.draftRef}` === ref);

            axios
                .get<OrderResponseApi>('/pedido/' + ref)
                .then((r) => {
                    const result = Object.assign(
                        new OrderResponseApi(),
                        r.data,
                    );

                    dispatch(
                        updateOrderState({
                            ...order,
                            orderApi: result,
                            order: {
                                ...order?.order,
                                ...result.toOrderRequest(),
                                tempItems: result.toOrderRequest().tempItems,
                            },
                        }),
                    );

                    dispatch(selectCustomer(result.toOrderRequest().customer));

                    if (!result?.codigoMoeda && !selectedMoeda) {
                        history.replace(`/create-order/select-type?draft=true&ref=${ref}`)
                    }
                    setLoading(false);
                })
                .catch(console.error);
        } else {
            dispatch(updateOrder({ customer, orderType }));
            setLoading(false);

            if (!selectedMoeda?.codigo) {
                history.replace("/create-order/select-type")
            }
        }
    }, []);

    // apenas chama essa funcao em novo pedido
    const getItems = async (): Promise<void> => {
        const isDraft = query.get('draft') === 'true';

        if (
            paymentCondition && paymentCondition.id &&
            selectItemsState.availablePaymentConditions.length > 0 &&
            !isDraft
        ) {
            if (!selectItemsState.draftRef) {
                dispatch(fetchAvailableItems({ paymentConditionId: `${paymentCondition.id}` }));
            }
        } else if (
            paymentCondition && paymentCondition.id &&
            !selectItemsState.request?.paymentConditionId &&
            !isDraft
        ) {
            message.info({
                content: translate('general.suggestionNotFound'),
                key: 'suggestion-not-found',
            });
            dispatch(
                fetchAvailableItems({ paymentConditionId: `${paymentCondition.id}` }),
            );
        }
    }

    useEffect(() => {
        if (canFetchItems) {
            getItems()
        }
    }, [canFetchItems, orderState?.order?.orderType?.platform?.id]);

    useEffect(() => {
        if (campaignsState.availableCampaignForOrder.length > 0)
            dispatch(updateCampaignItems());
    }, [
        campaignsState.selectedCampaigns,
        campaignsState.availableCampaignForOrder,
    ]);

    useEffect(() => {
        dispatch(fetchMinValueForOrder());
    }, [orderState.availableItems, orderState.order.addCustomerSelected]);

    return (
        <OrderContextProvider>
            <Page
                title={translate('pages.newOrderItems')}
                greyBackground
                onMenuClick={goBack}
                menuIcon={<LeftOutlined />}
                loading={loading}
            >
                <Row
                    justify="center"
                    style={{
                        backgroundColor: '#fff490',
                        borderRadius: '4px',
                        padding: '5px',
                        display:
                            !!discountPanteci ||
                                !!orderHistory?.valorSuframa ||
                                !!orderHistory?.valorTrade
                                ? 'flex'
                                : 'none',
                    }}
                >
                    <Col span={6}>
                        {!!discountPanteci && (
                            <DiscountWarning
                                description={`${translate(
                                    'general.activatedEarlyDiscount',
                                )}(${discountPanteci}%)`}
                            />
                        )}
                        {!!orderHistory?.valorSuframa && (
                            <DiscountWarning
                                description={`${translate(
                                    'general.suframaDiscount',
                                )}: ${formatMoney(orderState?.selectedMoeda?.simbolo, orderHistory?.valorSuframa)}`}
                            />
                        )}
                        {!!orderHistory?.valorTrade && (
                            <DiscountWarning
                                description={`${translate(
                                    'general.tradeValue',
                                )}: ${formatMoney(orderState?.selectedMoeda?.simbolo, orderHistory?.valorTrade)}`}
                            />
                        )}
                    </Col>
                </Row>

                <ChangeDiscountContextProvider>
                    <SelectItemsHeader readOnly={!!query.get('readOnly')} />
                    {!query.get('readOnly') && <SelectItemsBody />}
                    {query.get('readOnly') && (
                        <SelectItemsBodyBonification
                            key={`semaphores-${semaphores.length}`}
                            order={{
                                ...orderHistory,
                                availableItems: orderHistory.tempItems?.map(
                                    (i) => {
                                        const semaphore =
                                            calculateItemSemaphore(
                                                { ...i },
                                                false,
                                                rootState,
                                            );

                                        return { ...i, semaforo: semaphore };
                                    },
                                ),
                            }}
                        />
                    )}
                    {!query.get('readOnly') && <SelectItemsActions />}
                    <SelectDeliveryCustomerModal />
                    <ChangeDiscountModal />
                </ChangeDiscountContextProvider>
            </Page>
        </OrderContextProvider>
    );
};
